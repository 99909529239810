/*CSS Reset*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
header,
hgroup,
nav,
section,
article,
aside,
footer,
figure,
figcaption,
menu,
button {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  line-height: 1.5;
  font-size: 16px;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

fieldset,
img {
  border: 0;
}

li {
  list-style: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

input,
button,
textarea,
select,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
}

button {
  -webkit-appearance: none;
  border: 0;
  background: none;
}

a {
  -webkit-touch-callout: none;
  text-decoration: none;
}

:focus {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

em,
i {
  font-style: normal;
}

.hide {
  display: none !important;
}

.hide2 {
  opacity: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  opacity: 0;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 5px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  vertical-align: middle;
}

.swiper-pagination-bullet-active {
  background: #ff6ea2;
}

.wrap {
  width: 100%;
  min-width: 320px;
  max-width: 750px;
  min-height: 100%;
  margin: 0 auto;
}

.wrapper {
  min-width: 1200px;
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
}

.pcswiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  transition: all .3s;
  cursor: pointer;
}

.pcswiper .swiper-pagination-bullet-active {
  width: 20px;
  height: 8px;
  border: 0;
  border-radius: 10px;
  background: #fff;
}

.pcswiper .swiper-slide img {
  width: 100%;
}
